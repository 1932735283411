<template>
  <div class="chain-cafes-list">
    <OrderLoader v-if="loading && !error" />
    <LoadError :error="error" v-if="error" />

    <div v-if="!loading && !error">
      <nav>
        <div class="filter-header">
          <div>
            <!-- <small>Marketplace</small> -->
            <h5>{{ chain.name }}</h5>
          </div>
        </div>
      </nav>

      <div class="cafes-list">
        <MarketplaceCafeCard
          v-for="cafe in cafes"
          :key="cafe._id"
          :cafe="cafe"
          :is_direct_chain="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import OrderLoader from "@/components/OrderLoader.vue";
import LoadError from "@/components/LoadError.vue";

import ScheduleService from "@/services/schedule";

import MarketplaceCafeCard from "@/components/marketplace/MarketplaceCafeCard.vue";

import ChainAPI from "@/api/chain";

export default {
  components: {
    OrderLoader,
    LoadError,
    MarketplaceCafeCard,
  },
  data() {
    return {
      loading: true,
      error: null,

      chain: null,
      cafes: null,
    };
  },
  methods: {
    fetchChain() {
      ChainAPI.fetchChain(this.$route.params.chain_id)
        .then((data) => {
          this.chain = data.chain;
          this.cafes = data.cafes;
          this.processSchedules();
          this.loading = false;
        })
        .catch((error) => {
          this.error = error;
        });
    },
    processSchedules() {
      this.cafes.forEach((cafe, index) => {
        const helper = new ScheduleService.ScheduleHelper(cafe.schedule);

        const schedule_verdict = {
          open: helper.isOpen(),
          text: helper.format(),
          workday: helper.isWorkday(),
          time_left: helper.remainingTimeHours(),
          open_hours: helper.hoursTillOpen(),
        };

        this.$set(this.cafes[index], "schedule_verdict", schedule_verdict);
      });
    },
  },
  mounted() {
    this.fetchChain();

    window.document.body.style.backgroundColor = this.isDarkMode
      ? "black"
      : "#ededed";
  },
};
</script>

<style scoped>
nav {
  height: 7.7vh;
  z-index: 3;
  align-items: center;
  top: 0;
  position: fixed;
  width: 100%;
  background: #1d1d1b;
  color: #ededed;
  display: flex;
  padding: 1.8vh 2.5vh;
  justify-content: space-between;
}

nav.dark {
  background: #1d1d1d;
}

.filter-header img.back {
  height: 3.4vh;
  width: auto;
  margin: 0 1.2vh 0 -1vh;
}

.filter-header {
  display: flex;
  align-items: center;
}

.filter-header h5 {
  margin: 0;
  width: max-content;
  font-size: 2.2vh;
}

div small {
  color: #a4a3a1;
  padding: 0;
  display: flex;
  font-size: 1.6vh;
  line-height: normal;
}

.cafes-list {
  animation: slideup 1s;
  width: auto;
  margin-left: 1.3vh;
  padding-bottom: 13vh;
  margin-top: calc(7vh + 1.65vh);
}
</style>