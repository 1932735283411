<template>
  <div class="card-box" @click="navigateToCafe(cafe)">
    <img
      :src="cafe.image ? cafe.image : '/icons/cafe-placeholder.jpg'"
      :alt="`${cafe.name} logo`"
    />
    <div class="labels">
      <div class="label i" v-if="cafe.modes.inside">INSIDE</div>
      <div class="label r" v-if="cafe.modes.reserve">RESERVE</div>
      <div class="label d" v-if="cafe.modes.delivery">DELIVERY</div>
      <div class="label p" v-if="cafe.modes.preorder">TAKEAWAY</div>
    </div>

    <div class="info" v-thematic>
      <div class="main-cafe-wrapper">
        <span class="cafe-name-wrap">
          {{ cafe.name }}
        </span>

        <div class="sub-info-box">
          <span class="cafe-pricing">{{ cafe.price_level || "$" }}</span>
          <span class="cafe-rating" v-if="cafe.reviews_rating">
            {{ cafe.reviews_rating }}
            <img src="../../assets/hand-white.svg" />
            <!-- <img src="../../assets/hand-white.svg" v-else /> | -->
          </span>
          <span class="discount" v-thematic v-if="cafe.cashback_percent"
            >{{ cafe.cashback_percent }}%</span
          >
        </div>

        <div class="worktime-box w-50">
          <small
            v-thematic
            class="info-schedule-closed"
            v-if="cafe.billing_suspended"
          >
            <img src="../../assets/timer-red.svg" class="icon" />
            {{ $t("marketplace.list.closed") }}
          </small>
          <small
            v-thematic
            v-if="cafe.schedule_verdict.open && !cafe.billing_suspended"
          >
            <img src="../../assets/timer.svg" class="icon" v-if="!isDarkMode" />
            <img src="../../assets/timer-dark.svg" v-else class="icon" />
            {{ cafe.schedule_verdict.text }}</small
          >
          <small
            v-thematic
            class="info-schedule-closed"
            v-if="!cafe.schedule_verdict.open && !cafe.billing_suspended"
          >
            <img
              src="../../assets/timer-red.svg"
              class="icon"
              v-if="!cafe.schedule_verdict.workday"
            />
            <span v-if="!cafe.schedule_verdict.workday">{{
              $t("marketplace.list.closed")
            }}</span>
            <span
              v-thematic
              v-if="cafe.schedule_verdict.workday && !cafe.billing_suspended"
            >
              <img src="../../assets/timer-red.svg" class="icon" />
              {{ $t("marketplace.list.closed") }}
            </span>
          </small>
          <small class="till-close" v-if="!cafe.schedule_verdict.workday">{{
            $t("marketplace.list.day_off")
          }}</small>
          <small class="till-close" v-else-if="cafe.schedule_verdict.open && cafe.schedule_verdict.is_24hr">
            {{ $t("marketplace.list.open_24hr") }}
          </small>
          <small
            class="till-close"
            v-else-if="cafe.schedule_verdict.open && !cafe.billing_suspended"
          >
            {{ cafe.schedule_verdict.time_left }}
            {{ $t("marketplace.list.closes_in") }}
          </small>
          
          <small class="till-close" v-else>
            {{ cafe.schedule_verdict.open_hours }}
            {{ $t("marketplace.list.opens_in") }}
          </small>
        </div>

        <div class="address-distance-box w-50" v-thematic>
          <div class="location-pic-wrapper">
            <img
              src="../../assets/location-dark.svg"
              v-if="isDarkMode"
              class="icon"
            />
            <img src="../../assets/location.svg" v-else class="icon" />
            <small class="cafe-address" v-thematic>
              {{ formatAddress(cafe.address) }}
            </small>
          </div>

          <small class="distance" v-if="cafe.distance"
            >{{ cafe.distance }} {{ $t("marketplace.list.distance") }}</small
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MarketplaceService from "@/services/marketplace"

export default {
  props: {
    cafe: Object,
    is_direct_chain: {
      type: Boolean,
      default: false
    }
  },
  methods: {
      navigateToCafe(cafe) {
      if (
        cafe.modes.inside &&
        !cafe.modes.preorder &&
        !cafe.modes.delivery &&
        !cafe.modes.reserve
      ) {
        return;
      }

      if (this.is_direct_chain) {
        MarketplaceService.set(cafe.online_menu_link, false);
      } else {
        MarketplaceService.set(cafe.online_menu_link, true);
      }

      if (`${cafe.online_menu_link}` == "djigit") {
        window.location.href = `https://cafe.qrwaiter.com.ua/${cafe.online_menu_link}`;
        return;
      }

      this.$router.push({
        name: "choose_mode",
        params: {
          code: cafe.online_menu_link,
        },
      });
    },
    formatAddress(raw) {
      let first = raw.split(',').map(s => s.trim()).slice(0,2).join(',');
      const street_names = [
        'вул.', 'ул.' , 'вулиця', 'улица'
      ];

      street_names.forEach(s => first = first.replace(s, ''));

      return first;
    },  
  }
};
</script>

<style scoped>
.worktime-box,
.address-distance-box {
  padding-top: 1.3vh;
  align-self: end;
}

.worktime-box {
  padding-right: 0.6vh;
}

.address-distance-box {
  padding-left: 0.6vh;
}

small.till-close,
small.distance {
  padding-top: 0.6vh;
}

.worktime-box small {
  align-items: center;
}

.location-map-box,
.till-close-distance-box {
  display: flex;
  align-items: baseline;
  width: 100%;
  justify-content: space-between;
}

.worktime-address-box small {
  align-items: center;
  width: 50%;
}

.cafe-address,
.distance {
  margin-right: auto;
}

.till-close-distance-box {
  width: 100%;
  justify-content: unset;
}

.till-close-distance-box small {
  color: #c1c1c1;
}

.till-close-distance-box.dark small {
  color: #464646;
}

button.go2main {
  /* background: #e2223b; */
  background: #cbd9e6;
  color: #1d1d1b;
  border: none;
  border-radius: 1vh;
  padding: 0.9vh 2vh;
  outline: none;
  cursor: pointer;
  z-index: 5;
  width: 18vh;
  height: 4.8vh;
  font-size: 2vh;
  line-height: 0.2vh;

  z-index: 20;
}

.labels {
  display: flex;
  flex-flow: column;
  position: absolute;
  top: 0.5vh;
  right: -0.5vh;
}

.label {
  font-size: 1vh;
  text-align: center;
  width: 7vh;
  padding: 0.5vh 0;
  line-height: initial;
  color: #1d1d1b;
  letter-spacing: -1px;
  margin-bottom: 0.6vh;
  font-weight: bold;
  border-radius: 0.5vh;
}

.filter-header,
.cities-header {
  display: flex;
  width: 100%;
  align-items: center;
}

.cities-header h5,
.filter-header h5 {
  margin: 0;
  width: max-content;
  font-size: 2.2vh;
}

div small {
  color: #464646;
  padding: 0;
  display: flex;
  font-size: 1.6vh;
  line-height: normal;
}

div small.dark {
  color: #908f8d;
}

.filter-header h4 {
  margin: 0;
  font-size: 2.7vh;
}

.cafe-rating {
  display: flex;
  width: auto;
  padding: 0 1.7vh;
  border-left: 1px solid #c1c1c1;
  color: #c1c1c1;
  font-size: 1.5vh;
  justify-content: center;
}

.location-pic-wrapper {
  display: flex;
  align-items: center;
}

span.discount,
.cafe-pricing {
  font-size: 1.5vh;
  color: #c1c1c1;
}

.cafe-pricing {
  padding-right: 1.2vh;
}

span.discount {
  padding-left: 1.2vh;
  border-left: 1px solid #c1c1c1;
}

.cafe-rating img {
  height: 1.4vh !important;
  width: auto !important;
  margin: 0 0 0 0.5vh;
  border-radius: 0 !important;
}

.label.i {
  background: #1d1d1b;
  color: #ededed;
}
.label.r {
  background: #aeccc6;
  color: #1d1d1b;
}
.label.d {
  background: #cbd9e6;
}
.label.p {
  background: #ffd2a8;
}

small {
  display: block;
  font-size: 1.6vh;
  line-height: 0.8;
  padding-top: 0.7vh;
}

img.list-logo {
  height: 5vh;
  width: auto;
  display: block;
  margin: 5vh auto 0;
}

.wrapper {
  position: absolute;
  width: 100%;
  z-index: 1;
  top: 7.7vh;
  background: #ededed;
  height: auto;
}

.wrapper.dark {
  background: black;
}

small.current-location,
.map-text {
  padding: 1.9vh 2.5vh 0;
  font-size: 1.65vh;
  color: #908f8d;
  line-height: normal;
}

img.loc {
  height: 2.3vh;
  width: 2.3vh;
}

nav img.logo {
  height: 3.8vh;
  width: auto;
  z-index: 20;
}

.back2 {
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.21) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  backdrop-filter: blur(3px);
  height: 12vh;
  width: 100%;
  position: fixed;
  bottom: 0;
}

.cities-header img.back,
.filter-header img.back {
  height: 3.4vh;
  width: auto;
  margin: 0 1.2vh 0 -1vh;
}

.back2 img {
  position: relative;
  top: 50%;
  left: 2.5vh;
  transform: translateY(-50%);
  height: 7.4vh;
  width: auto;
}

.content {
  background: #ededed;
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;
  top: 0;
  padding-top: 7.7vh;
}

.content.dark {
  background: black;
}

.fav-cafes-map {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 1vh 2.5vh 2vh 0;
  margin: 0 0 0 2.5vh;
  border-bottom: 1px solid #c4c4c4;
}

.fav-cafes-map h4 {
  margin: 0;
  font-size: 2.2vh;
}

.fav-cafes-map h4.dark {
  color: #ededed;
}

.fav-cafes-map span {
  font-size: 1.7vh;
  color: #908f8d;
}

.cafe-categories {
  display: flex;
  padding: 11vh 0 0;
  overflow: auto;
}

.line {
  width: 100%;
  margin-left: 2.5vh;
  z-index: 0;
  top: 35vh;
  position: absolute;
  border-top: 1px solid #c4c4c4;
}

.no-border {
  border: none;
}

.item:first-child {
  margin-left: 2.5vh;
}

.item img {
  display: block;
  height: 11.7vh;
  margin-right: 1vh;
  width: auto;
}

span.category-title {
  display: block;
  color: #908f8d;
  font-size: 1.6vh;
  margin-left: -1vh;
  padding: 1.2vh 0 1.8vh;
  text-align: center;
}

span.category-title.dark {
  color: #908f8d;
}

.category-active {
  color: #1d1d1b !important;
}

.category-active.dark {
  color: #ededed !important;
}

.cards {
  animation: slideup 1s;
  width: auto;
  margin-left: 1.3vh;
  padding-bottom: 13vh;
}

@keyframes slideup {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(inherit);
    opacity: 1;
  }
}

.card-box {
  position: relative;
  cursor: pointer;
  display: flex;
  width: 100%;
  height: auto;
  margin: 1.2vh 0 0 0;
  border-radius: 1.2vh 0 0 1.2vh;
  transition: 0.3s;
  flex-flow: column wrap;
}

.card-box img {
  height: 22vh;
  width: 100%;
  object-fit: cover;
  border-radius: 1.2vh 0 0 1.2vh;
  border-bottom-left-radius: 0;
}

img.icon {
  width: auto;
  margin-right: 0.5vh;
  height: 1.5vh;
  border-radius: 0;
}

input.search {
  width: 100%;
  background: transparent;
  display: block;
  font-size: 2.3vh;
  padding: 0 0 0 1vh;
  border: none;
  outline: none;
  color: #1d1d1d;
}

input.search.dark {
}

.filter-wrapper {
  justify-content: flex-end;
  display: flex;
  align-items: center;
}

.filter-wrapper .search-block.right {
  right: -8vw;
}

.search-block {
  text-align: center;
  display: flex;
  position: relative;
  border-radius: 1.2vh 0 0 1.2vh;
  background: transparent;
  border: 1px solid #908f8d;
  padding: 0.6vh 1.2vh;
  margin: 0;
  width: 15vw;
}

.search-block.dark {
  /* background: black; */
}

.search-block.right {
  transition: 0.5s;
  border: 1px solid #908f8d;
  right: -8vw;
}

.search-block.right.active {
  right: -3vh;
  width: 85vw;
  background: #ededed;
}

img.search-icon {
  height: 2.7vh;
  width: auto;
  position: absolute;
  z-index: -1;
  top: 50%;
  transform: translateY(-50%);
}

img.filter {
  height: 2.7vh;
  margin: 0 -2vh 0 0;
  width: auto;
}

img.close {
  height: 2vh;
  margin: 0 2vh;
  opacity: 1;
  width: auto;
}

.info {
  position: relative;
  width: 100%;
  border-radius: 0 0 0 1.2vh;
  color: #1d1d1d;
  background: white;
  padding: 1.3vh 1.9vh;
}

.info.dark {
  background: #1d1d1d;
  color: #ededed;
}

.info h4 {
  font-size: 2.5vh;
  margin: 0;
  line-height: normal;
}

.loc-box {
  display: flex;
  align-items: center;
  align-self: center;
  height: inherit;
  justify-content: center;

  width: 100%;
  height: 4.8vh;
  left: 0;
  right: 0;
  cursor: pointer;
  position: absolute;
}

nav span {
  display: block;
  color: #ededed;

  font-size: 3vh;
}

.info-schedule-closed span {
  display: flex;
  align-items: center;
}

.error-block {
  color: #e2223b;
  text-align: center;
  margin-top: 15vh;
}

.error-block img {
  width: 40vh;
}

.no-cafes-container {
  color: #908f8d;
  text-align: center;
  position: relative;
  top: 1vh;
  left: 0;
  right: 0;
  width: 100%;
}

.no-cafes-container h4 {
  margin: 0;
  font-size: 2.5vh;
  padding: 1.1vh;
}

.no-cafes-container img {
  width: 35vh;
  height: auto;
}

.loading-panda {
  width: auto;
  height: 10vh;

  margin-top: 1vh;
}

.list-loader {
  text-align: center;
  margin-top: 12vh;
}

.current-filter {
  color: #129b81 !important;
}

.main-cafe-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-flow: row wrap;
}

.sub-info-box {
  display: flex;
  line-height: 1;
}

.cafe-name-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  padding-right: 1vh;
  font-size: 2.2vh;
  text-overflow: ellipsis;
  width: 55%;
  line-height: normal;
}

.info-schedule-closed {
  color: #e2223b !important;
  display: flex;
  align-items: center;
}

</style>